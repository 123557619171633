import React, { useState, useEffect } from "react";
import close from "../../Assets/png/close-square.png";
import { FaRegFilePdf } from "react-icons/fa6";
import Button from "../../../Common/shared/Button/button";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { useForm } from "react-hook-form";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { GetFollowUpByLeadId } from "../../../Services/followUpService.ts";
import { GetAllTypes } from "../../../Services/udcService.ts";
import { GetAllUsers } from "../../../Services/authService.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import { SaveFollowUpData } from "../../../Services/followUpService.ts";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";

function LeadFollowUp({ name, item }) {
  const [alert, setAlert] = useState("");
  const { startLoading, stopLoading } = useLoading();
  const [displayModal, setDisplayModal] = useState(false);
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskTo, setTaskto] = useState([]);
  const [followUpStatus, setfollowUpStatus] = useState([]);
  const [FollowupData, setFollowupData] = useState([]);
  const [form, setForm] = useState(false);
  const getFilename = (url) => {
    const path = url.split('/').pop(); // Extract the file name from the URL
    return path;
  };
  
  const [defaultOption, setDefaultOption] = useState({
    taskTypeOption: null,
    taskToOption: null,
    followUpOption: null,
  });
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        startLoading();
        const resultStatus = await GetAllTypes("Leads");
        const resultFollowUpType = [
          { id: item.id, val: "Lead" },
          { id: item.customerId, val: "Client" },
        ];
        const resultUsers = await GetAllUsers();
        const resultFollowUpData = await GetFollowUpByLeadId(item.id);

        if (Array.isArray(resultStatus)) {
          setfollowUpStatus(
            resultStatus.map((res) => ({
              value: res.id,
              label: res.value01,
            }))
          );
        }

        if (Array.isArray(resultFollowUpType)) {
          setTaskTypes(
            resultFollowUpType.map((res) => ({
              value: res.id,
              label: res.val,
            }))
          );
        }

        if (Array.isArray(resultUsers)) {
          setTaskto(
            resultUsers
              .filter((x) => x.roleName.toUpperCase() !== "SuperAdmin".toUpperCase())
              .map((res) => ({
                value: res.user.id,
                label: `${res.user.firstName} ${res.user.lastName}`,
              }))
          );
        }

        setFollowupData(resultFollowUpData);
      } catch (error) {
        snackBarStore.showSnackBar(`Error fetching data: ${error}`, "error");
      } finally {
        stopLoading();
      }
    };
    fetchData();
  }, [item]);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleDropdownChange = (dropDwnId, selected) => {
    setDefaultOption((prev) => ({
      ...prev,
      [dropDwnId]: selected,
    }));
  };

  const onSubmit = async (data) => {
    startLoading();
    const dates = `${new Date(data.date).getFullYear()}-${("0" + new Date(data.date).getMonth()).slice(-2)}-${new Date(data.date).getDate()}`;
    const followUpData = {
      followupType: defaultOption.taskTypeOption.label,
      followupTypeId: defaultOption.taskTypeOption.value,
      title: data.title,
      followupStatus: defaultOption.followUpOption.value,
      assignedTo: defaultOption.taskToOption.value,
      followupDate: dates,
      followupTime: new Date(`${dates}T${data.time}`),
      attachmentUrl: "",
      fileRequest: imageSrc,
      remark: data.description,
      isActive: true,
    };

    try {
      await SaveFollowUpData(followUpData);
      setFollowupData((prev) => [...prev, followUpData]); // Add the new follow-up data to the table
    } catch (error) {
      snackBarStore.showSnackBar(`Error while Saving task: ${error}`, "error");
    } finally {
      stopLoading();
      setForm(false); // Hide the form after submission
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc({ name: file.name, extension: `.${file.name.split('.').pop()}`, data: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = (fileUrl) => async () => {
    try {
      startLoading();
      const response = await fetch(fileUrl);
      const contentDisposition = response.headers.get("content-disposition");
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "download";
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      snackBarStore.showSnackBar(`Error while downloading file: ${error}`, "error");
    } finally {
      stopLoading();
    }
  };

  return (
    <div className="App2">
      <div className="Button2" onClick={() => setDisplayModal(!displayModal)}>
        {name}
      </div>

      <div className={`leadModal ${displayModal ? "leadShow" : ""}`}>
        <div className="leadinfo-Top">
          <h6 className="leadInfo-heading">Follow up</h6>
          <img src={close} alt="" className="leadinfo-closebutton" onClick={() => setDisplayModal(!displayModal)} />
        </div>

        <div className="leadinfo-content">
          <div className="followUp-button">
            <Button variant="smallButton" prefixIcon={<SVGIcons.Add />} onClick={() => setForm(true)}>
              Add Follow up
            </Button>
          </div>

          {form && (
            <form className="followup-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="fu-form1" style={{ gap: "20px" }}>
                <div className="inputForm if2">
                  <label className="followup-label" htmlFor="FollowType">Follow Up Type</label>
                  <Dropdown
                    options={taskTypes}
                    className={`dropdown-fu dropdown-leads ${defaultOption.taskTypeOption === "" ? "error-border" : ""}`}
                    onChange={(e) => handleDropdownChange("taskTypeOption", e)}
                    value={defaultOption.taskTypeOption}
                    placeholder="Select Follow Up Type"
                  />
                  {defaultOption.taskTypeOption === "" && <span className="errorLogin label3">Follow Up Type required</span>}
                </div>
                <div className="inputForm if2">
                  <label className="followup-label" htmlFor="Title">Title</label>
                  <input
                    type="text"
                    className={`input-fu input10 ${errors.title ? "error-border" : ""}`}
                    placeholder="Enter title here"
                    {...register("title", { required: "Title is required" })}
                    aria-invalid={errors.title ? "true" : "false"}
                  />
                  {errors.title && <div className="errorLogin label3">{errors.title.message}</div>}
                </div>
                <div className="inputForm if2">
                  <label className="followup-label" htmlFor="Status">Status</label>
                  <Dropdown
                    options={followUpStatus}
                    className={`dropdown-fu dropdown-leads ${defaultOption.followUpOption === "" ? "error-border" : ""}`}
                    onChange={(e) => handleDropdownChange("followUpOption", e)}
                    value={defaultOption.followUpOption}
                    placeholder="Select Follow Up Status"
                  />
                  {defaultOption.followUpOption === "" && <span className="errorLogin label3">Follow Up Status required</span>}
                </div>
              </div>

              <div className="fu-form1" style={{ gap: "20px" }}>
                <div className="inputForm if2">
                  <label className="followup-label" htmlFor="AssignTo">Assign To</label>
                  <Dropdown
                    options={taskTo}
                    className={`dropdown-fu dropdown-leads ${defaultOption.taskToOption === "" ? "error-border" : ""}`}
                    onChange={(e) => handleDropdownChange("taskToOption", e)}
                    value={defaultOption.taskToOption}
                    placeholder="Select Person"
                  />
                  {defaultOption.taskToOption === "" && <span className="errorLogin label3">Assigned To required</span>}
                </div>
                <div className="inputForm if2">
                  <label className="followup-label" htmlFor="Attachment">Attachment</label>
                  <input
                    type="file"
                    className={`input-fu input10 ${errors.attachment ? "error-border" : ""}`}
                    {...register("attachment", { required: "Attachment is required" })}
                    onChange={handleFileChange}
                  />
                  {errors.attachment && <div className="errorLogin label3">{errors.attachment.message}</div>}
                </div>
              </div>

              <div className="fu-form1" style={{ gap: "20px" }}>
                <div className="inputForm if2">
                  <label className="followup-label" htmlFor="Date">Schedule Meeting / Call</label>
                  <input
                    type="date"
                    className={`input-fu input10 ${errors.date ? "error-border" : ""}`}
                    {...register("date", { required: "Date is required" })}
                  />
                  {errors.date && <div className="errorLogin label3">{errors.date.message}</div>}
                </div>
                <div className="inputForm if2">
                  <label className="followup-label fu-time-txt" htmlFor="time">Time</label>
                  <input
                    type="time"
                    className={`input-fu input10 ${errors.time ? "error-border" : ""}`}
                    {...register("time", { required: "Time is required" })}
                  />
                  {errors.time && <div className="errorLogin label3">{errors.time.message}</div>}
                </div>
              </div>

              <div className="inputForm if2">
                <label className="followup-label" htmlFor="Description">Description</label>
                <textarea
                  type="text"
                  className={`input-fu-textarea input10 ${errors.description ? "error-border" : ""}`}
                  placeholder="Enter Description"
                  {...register("description", { required: "Description is required" })}
                />
                {errors.description && <div className="errorLogin label3">{errors.description.message}</div>}
              </div>

              <div className="fu-submit-cancel">
                <Button variant="mediumOutline" onClick={() => setForm(false)}>Cancel</Button>
                <Button variant="mediumButton">Submit</Button>
              </div>
            </form>
          )}

          <div className="history">
            {FollowupData.map((val) => (
              <div key={val.id}>
                <div className="history1">
                  <div className="history1-left">
                    <div className="history-circle">
                      <div className="history-circle1"></div>
                      <div className="history-circle2"></div>
                    </div>
                    <div className="history1-text">
                      <div className="history-name">{val.assignedToName}</div>
                      <div className="history1-text2 label3">
                        <div className="label3" style={{ textAlign: "left" }}>{val.followupStatusName}</div>
                        <div className="label3">Date {val.followupDate} {new Date(val.followupTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</div>
                      </div>
                      <div className="history1-text3 label3">
                        <div className="history1-text3-1">{val.remark}</div>
                      </div>
                      <div className="history1-pdf">
                        <div className="history1-pdf1">
                          <FaRegFilePdf size={18} onClick={handleDownload(val.attachmentUrl)} />
                        </div>
                        <div className="history1-pdf2">
                          {getFilename(val.attachmentUrl)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history1-right">
                    <div className="history-circle">
                      <div className="history-circle2"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadFollowUp;
