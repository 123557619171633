import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import close from "../../Assets/png/close-square.png";
import { FaAngleDown } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa6";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { PiCaretUpDownFill } from "react-icons/pi";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import Button from "../../../Common/shared/Button/button";
import AddServicesClient from "./addService";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Multiselect from "multiselect-react-dropdown";
import { ContactInfo } from "../LeadInfo/leadInfo";

const options = ["one", "two", "three"];
const defaultOption = null;

function ClientInfo({ name, item }) {
  const [alert, setAlert] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const itemsPerPage = 20;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadHistory, setLeadHistory] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://dev-d478mkay4qiodj5.api.raw-labs.com/api/json"
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getinfo(name) {
    switch (name) {
      case "Mobile Number":
        return item.phoneNumber;
      case "Email Id":
        return item.email;
      case "Whatsapp Number":
        return item.whatsappNumber;
      case "Address":
        return item.address;
      case "City":
        return item.city;
      case "State":
        return item.state;
      case "Country":
        return item.country;
      case "Pincode":
        return item.pincode;
      case "Occupation":
        return item.occupation;
      case "Type of Work":
        return item.typeOfWork;
      case "Monthly Income":
        return item.monthlyIncome;
      case "Company Name":
        return item.companyName;
      case "Product":
        return item.product;
      case "Company":
        return item.productCompany;
      case "Plan":
        return item.plan;

      default:
        return null;
    }
  }

  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Function to handle pagination
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate which page numbers to display (current, previous, next)
  const pageNumbersToDisplay = [
    currentPage - 1,
    currentPage,
    currentPage + 1,
  ].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

  // Get current items to display based on current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const clearMessage = setTimeout(() => {
      setAlert("");
    }, 5000);
    return () => clearTimeout(clearMessage);
  }, [alert]);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  //FOLLOW UP
  const [displayModal2, setDisplayModal2] = useState(false);
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  const [displayFollowUp2, setDisplayFollowUp2] = useState(false);
  const [form, setForm] = useState(false);

  const options2 = [
    { id: 1, name: "Option 1" },
    { id: 2, name: "Option 2" },
    { id: 3, name: "Option 3" },
    // Add more options as needed
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    setSelectedOptions(selectedList);
  };

  const handleStatusChange = (option) => {
    // setSelectedStatus(option.value);
    if (option.value === "three") {
      setDisplayFollowUp2(true);
    } else {
      setDisplayFollowUp2(false);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    const clearMessage = setTimeout(() => {
      setAlert("");
    }, 5000);
    return () => clearTimeout(clearMessage);
  }, [alert]);

  const onSubmit = (data) => {
    console.log(data); // You can handle form submission here
    setDisplayModal(!displayModal);
    //toast.success("follow up Added")
  };

  const handleAddFollow = () => {
    setDisplayModal(false);
  };

  return (
    <div className="App2">
      <div className="Button2" onClick={() => setDisplayModal(!displayModal)}>
        {name}
      </div>

      <div className={`leadModal ${displayModal ? "leadShow" : ""} `}>
        <div className="leadinfo-Top">
          <h6 className="leadInfo-heading">Client Information</h6>
          <img
            src={close}
            alt=""
            className="leadinfo-closebutton"
            onClick={() => setDisplayModal(!displayModal)}
          />
        </div>
        <div className="leadinfo-content">
          <div className="leadinfo-contentTop">
            <div className="leadinfo-contentTop-section">
              <div className="label3">Client Id</div>
              <div className="leadinfo-data1 label1">{item.serialNumber}</div>
            </div>
            <div className="leadinfo-contentTop-section">
              <div className="label3">Name</div>
              <div className="leadinfo-data1 label1">{item.name}</div>
            </div>
            <div className="leadinfo-contentTop-section">
              <div className="label3">Client Status</div>
              <div className="leadinfo-data1 label1">{item.status}</div>
            </div>
            <div className="leadinfo-contentTop-section">
              <div className="label3">Client Owner</div>
              <div className="leadinfo-data1 label1">Jay P</div>
            </div>
            <div className="leadinfo-contentTop-section">
              <div className="label3">CLient Source</div>
              <div className="leadinfo-data1 label1">Online</div>
            </div>
            <div>
              {/* <LeadFollowUp name={
                                
                            } item={item}
                            /> */}
              <div className="App2">
                <div className="Button2">
                  <Button onClick={handleAddFollow}>
                    <div className="ci-fu-button">
                      <div className="ci-fu1">Add</div>
                      <div className="ci-fu1">Follow</div>
                      <div className="ci-fu1">up</div>
                    </div>
                  </Button>
                </div>

                <div
                  className={`leadModal ${displayModal2 ? "leadShow" : ""} `}
                >
                  <div className="leadinfo-Top">
                    <h6 className="leadInfo-heading">Follow up</h6>
                    <img
                      src={close}
                      alt=""
                      className="leadinfo-closebutton"
                      onClick={() => setDisplayModal(!displayModal)}
                    />
                  </div>
                  <div className="leadinfo-content">
                    <div className="followUp-button">
                      <Button
                        variant="smallButton"
                        prefixIcon={<SVGIcons.Add />}
                        onClick={() => setForm(true)}
                      >
                        Add Follow up
                      </Button>
                    </div>
                    {form && (
                      <form
                        className="followup-form"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="fu-form1" style={{ gap: "20px" }}>
                          <div className="inputForm if2 ">
                            <label className="followup-label" htmlFor="Title">
                              Title
                            </label>
                            <input
                              type="text"
                              className={`input-fu input10 ${
                                errors.title ? "error-border" : ""
                              }`}
                              placeholder="Enter title here"
                              {...register("title", {
                                required: "Title is required",
                              })}
                              aria-invalid={errors.title ? "true" : "false"}
                            />
                            {errors.title && (
                              <div className="errorLogin label3">
                                {errors.title.message}
                              </div>
                            )}
                          </div>
                          <div className="inputForm if2 ">
                            <label className="followup-label" htmlFor="Status">
                              Status
                            </label>
                            <Dropdown
                              options={options}
                              className="dropdown-fu"
                              value={defaultOption}
                              placeholder="Status"
                              onChange={handleStatusChange}
                            />
                            {errors.status && (
                              <div className="errorLogin label3">
                                {errors.status.message}
                              </div>
                            )}
                          </div>
                        </div>
                        {displayFollowUp2 && (
                          <div className="fu-form1" style={{ gap: "20px" }}>
                            <div className="inputForm if2">
                              <label
                                className="followup-label"
                                htmlFor="Status"
                              >
                                Services
                              </label>
                              <div className="div3">
                                <Multiselect
                                  options={options2} // Options to display in the dropdown
                                  selectedValues={selectedOptions} // Pre-selected values (optional)
                                  onSelect={handleSelect} // Function to handle selection
                                  onRemove={handleRemove} // Function to handle removal
                                  displayValue="name" // Property of options to display as label
                                  showCheckbox={true}
                                  avoidHighlightFirstOption={true}
                                  placeholder="Select Sub Category"
                                  className="div1"
                                />
                              </div>
                              {errors.status && (
                                <div className="errorLogin label3">
                                  {errors.status.message}
                                </div>
                              )}
                            </div>
                            <div className="inputForm if2 ">
                              <label className="followup-label" htmlFor="desc">
                                Description
                              </label>
                              <input
                                type="text"
                                className={`input-fu input10 ${
                                  errors.desc ? "error-border" : ""
                                }`}
                                placeholder="Enter desc here"
                                {...register("desc", {
                                  required: "desc is required",
                                })}
                                aria-invalid={errors.desc ? "true" : "false"}
                              />
                              {errors.desc && (
                                <div className="errorLogin label3">
                                  {errors.desc.message}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="fu-form1" style={{ gap: "20px" }}>
                          <div className="inputForm if2 ">
                            <label className="followup-label" htmlFor="Status">
                              Assign To
                            </label>
                            <Dropdown
                              options={options}
                              className="dropdown-fu"
                              value={defaultOption}
                              placeholder="Select Person"
                            />
                            {errors.status && (
                              <div className="errorLogin label3">
                                {errors.status.message}
                              </div>
                            )}
                          </div>

                          <div className="inputForm if2 ">
                            <label
                              className="followup-label"
                              htmlFor="Attachment"
                            >
                              Attachment
                            </label>
                            <input
                              type="file"
                              className={`input-fu input10 ${
                                errors.attachment ? "error-border" : ""
                              }`}
                              placeholder="Upload File here"
                              {...register("attachment", {
                                required: "Attachment is required",
                              })}
                              aria-invalid={
                                errors.attachment ? "true" : "false"
                              }
                            />
                            {errors.attachment && (
                              <div className="errorLogin label3">
                                {errors.attachment.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="fu-form1" style={{ gap: "20px" }}>
                          <div className="inputForm if2 ">
                            <label className="followup-label" htmlFor="Date">
                              Schedule Meeting / Call
                            </label>
                            <input
                              type="date"
                              className={`input-fu input10 ${
                                errors.date ? "error-border" : ""
                              }`}
                              placeholder="Email"
                              {...register("date", {
                                required: "date is required",
                              })}
                              aria-invalid={errors.date ? "true" : "false"}
                            />
                            {errors.date && (
                              <div className="errorLogin label3">
                                {errors.date.message}
                              </div>
                            )}
                          </div>
                          <div className="inputForm if2 ">
                            <label
                              className="followup-label fu-time-txt"
                              htmlFor="time"
                            >
                              Time
                            </label>
                            <input
                              type="time"
                              className={`input-fu input10 ${
                                errors.time ? "error-border" : ""
                              }`}
                              placeholder="time"
                              {...register("time", {
                                required: "Time is required",
                              })}
                              aria-invalid={errors.time ? "true" : "false"}
                            />
                            {errors.time && (
                              <div className="errorLogin label3">
                                {errors.time.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="inputForm if2 ">
                          <label
                            className="followup-label"
                            htmlFor="Description"
                          >
                            Description
                          </label>
                          <textarea
                            type="text"
                            className={`input-fu-textarea input10 ${
                              errors.description ? "error-border" : ""
                            }`}
                            placeholder="Enter Description"
                            {...register("description", {
                              required: "Email Address is required",
                            })}
                            aria-invalid={errors.description ? "true" : "false"}
                          />
                          {errors.description && (
                            <div className="errorLogin label3">
                              {errors.description.message}
                            </div>
                          )}
                        </div>

                        <div className="fu-submit-cancel">
                          <Button variant="mediumOutline">Cancel</Button>
                          <Button variant="mediumButton">Submit</Button>
                        </div>
                      </form>
                    )}

                    <div className="history">
                      <div className="history1">
                        <div className="history1-left">
                          <div className="history-circle">
                            <div className="history-circle1"></div>
                            <div className="history-circle2"></div>
                          </div>
                          <div className="history1-text">
                            <div className="history-name">Jay. P</div>
                            <div className="history1-text2 label3">
                              <div className="label3">
                                Jay. P Add Next Follow Up{" "}
                              </div>
                              <div className="label3">
                                Date 25-02-2024 02.00 PM
                              </div>
                            </div>
                            <div className="history1-text3 label3">
                              <div className="history1-text3-1">
                                Lorem Ipsum has been the industry's standard
                                ever since
                              </div>
                              <div className="history1-text3-2">
                                See more.....
                              </div>
                            </div>
                            <div className="history1-pdf">
                              <div className="history1-pdf1">
                                <FaRegFilePdf size={18} />
                              </div>
                              <div className="history1-pdf2">Insurnace.pdf</div>
                            </div>
                          </div>
                        </div>
                        <div className="history1-right">20-02-2024</div>
                      </div>
                      <div className="history1">
                        <div className="history1-left">
                          <div className="history-circle">
                            <div className="history-circle1"></div>
                            <div className="history-circle2"></div>
                          </div>
                          <div className="history1-text">
                            <div className="history-name">Jay. P</div>
                            <div className="history1-text2 label3">
                              <div className="label3">
                                Jay. P Add Next Follow Up{" "}
                              </div>
                              <div className="label3">
                                Date 25-02-2024 02.00 PM
                              </div>
                            </div>
                            <div className="history1-text3 label3">
                              <div className="history1-text3-1">
                                Lorem Ipsum has been the industry's standard
                                ever since
                              </div>
                              <div className="history1-text3-2">
                                See more.....
                              </div>
                            </div>
                            <div className="history1-pdf">
                              <div className="history1-pdf1">
                                <FaRegFilePdf size={18} />
                              </div>
                              <div className="history1-pdf2">Insurnace.pdf</div>
                            </div>
                          </div>
                        </div>
                        <div className="history1-right">20-02-2024</div>
                      </div>
                      <div className="history1">
                        <div className="history1-left">
                          <div className="history-circle">
                            <div className="history-circle1"></div>
                            <div className="history-circle2"></div>
                          </div>
                          <div className="history1-text">
                            <div className="history-name">Jay. P</div>
                            <div className="history1-text2 label3">
                              <div className="label3">
                                Jay. P Add Next Follow Up{" "}
                              </div>
                              <div className="label3">
                                Date 25-02-2024 02.00 PM
                              </div>
                            </div>
                            <div className="history1-text3 label3">
                              <div className="history1-text3-1">
                                Lorem Ipsum has been the industry's standard
                                ever since
                              </div>
                              <div className="history1-text3-2">
                                See more.....
                              </div>
                            </div>
                            <div className="history1-pdf">
                              <div className="history1-pdf1">
                                <FaRegFilePdf size={18} />
                              </div>
                              <div className="history1-pdf2">Insurnace.pdf</div>
                            </div>
                          </div>
                        </div>
                        <div className="history1-right">20-02-2024</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`Overlay ${displayModal2 ? "Show" : ""}`}
                  onClick={() => setDisplayModal(!displayModal)}
                />
                <p className="Alert">{alert}</p>
              </div>
            </div>
          </div>

          <hr className="leadinfoHr" />

          <div className="leadInfoBox">
            <button
              className={`accordion ${
                activeIndex === 0 ? "activeLeadInfo" : ""
              }`}
              onClick={() => toggleAccordion(0)}
            >
              <div className="label1">Contact Information</div>
              <div>
                <FaAngleDown
                  size={20}
                  className={`accordion-icon  ${
                    activeIndex === 0 ? "acordion-icon-active" : ""
                  }`}
                />
              </div>
            </button>
            <div
              className={`panel ${activeIndex === 0 ? "activeLeadInfo" : ""}`}
            >
              {ContactInfo.map((item1, index) => (
                <div className="leadInfo-single label5">
                  <div className="leadInfoname">{item1.name}</div>
                  <div className="leadInfovalue">{getinfo(item1.name)}</div>
                </div>
              ))}
            </div>

            <button
              className={`accordion label1 ${
                activeIndex === 1 ? "activeLeadInfo" : ""
              }`}
              onClick={() => toggleAccordion(1)}
            >
              <div className="label1">Occupation Details</div>
              <span className="accordion-icon">
                <FaAngleDown size={20} />
              </span>
            </button>
            {/* <div
              className={`panel ${activeIndex === 1 ? "activeLeadInfo" : ""}`}
            >
              {OccupationInfo.map((item1, index) => (
                <div className="leadInfo-single">
                  <div className="leadInfoname">{item1.name}</div>
                  <div className="leadInfovalue">{getinfo(item1.name)}</div>
                </div>
              ))}
            </div> */}

            <button
              className={`accordion label1 ${
                activeIndex === 2 ? "activeLeadInfo" : ""
              }`}
              onClick={() => toggleAccordion(2)}
            >
              <div className="label1">Interested Services</div>
              <span className="accordion-icon">
                <FaAngleDown size={20} />
              </span>
            </button>
            <div
              className={`panel ${activeIndex === 2 ? "activeLeadInfo" : ""}`}
            >
              <table
                className="leadsTable roundedcorner addserv-table"
                id="leadsTable"
              >
                <thead>
                  <tr className="body2 roundedcorner">
                    <th
                      style={{ borderRadius: "10px 0px  0px  0px" }}
                      className="vraj"
                    >
                      <div className="leadsTH">
                        Dates <PiCaretUpDownFill size={12} />
                      </div>
                    </th>
                    <th className="vraj">
                      <div className="leadsTH">
                        Services <PiCaretUpDownFill />
                      </div>
                    </th>
                    <th className="vraj">
                      <div className="leadsTH">
                        SIP value <PiCaretUpDownFill />
                      </div>
                    </th>
                    <th className="vraj">
                      <div className="leadsTH">
                        Completion Date <PiCaretUpDownFill />
                      </div>
                    </th>
                    <th>
                      <div className="leadsTH">
                        Remark <PiCaretUpDownFill size={12} />
                      </div>
                    </th>
                    <th style={{ borderRadius: "0px 10px  0px  0px" }}>
                      <div
                        className="leadsTH"
                        style={{ borderRadius: "0px 10px  0px  0px" }}
                      >
                        Actions <PiCaretUpDownFill size={12} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="label2">11-11-24</td>
                    <td className="label2">Insurance</td>
                    <td className="label2">1000/-</td>
                    <td className="label2">13-11-24</td>
                    <td className="label2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Assumenda at delectus, fuga id dicta dolore
                    </td>
                    <td className="actionsTable">
                      <SVGIcons.Trash />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="leadsTableBottom">
                <div className="leadsTableBottomLeft">
                  <span className="body2 tablePageStatus">
                    Showing {rangeStart} to {rangeEnd} of {data.length} results
                  </span>
                </div>

                <div className="leadsTablePaginationButtons">
                  <button
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="leadsTablePaginationButton"
                  >
                    <MdOutlineKeyboardDoubleArrowLeft size={28} />
                  </button>

                  {pageNumbersToDisplay.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      onClick={() => handlePagination(pageNumber)}
                      disabled={pageNumber === currentPage}
                      className="leadsTablePaginationButtonNumber"
                    >
                      {pageNumber}
                    </button>
                  ))}
                  <button
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages)
                      )
                    }
                    disabled={currentPage === totalPages}
                    className="leadsTablePaginationButton"
                  >
                    <MdOutlineKeyboardDoubleArrowRight size={28} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="leadI-service1">
            <div className="services-li">
              <div className="label2 service1">Suggested Services:</div>
              <div className="label3 service2">Insurance</div>
              <div className="label3 service2">SIP</div>
              <div className="label3 service2">Demat</div>
              <div className="label3 service2">Mutual Funds</div>
            </div>
            <AddServicesClient
              name={
                <Button
                  variant="yellowButton"
                  prefixIcon={<SVGIcons.Add />}
                  className="addService"
                >
                  Add Service
                </Button>
              }
            />
          </div>

          <div className="history">
            <div className="historyHead label4">
              <div
                className="historyHead1"
                onClick={() => {
                  setLeadHistory(true);
                }}
              >
                <div
                  className={
                    !leadHistory ? "historyHead3 historyHead2" : "historyHead2"
                  }
                >
                  Lead History
                </div>
                <div className="historyHr"></div>
              </div>
              <div
                className="historyHead1"
                onClick={() => {
                  setLeadHistory(false);
                }}
              >
                <div
                  className={
                    leadHistory ? "historyHead3 historyHead2" : "historyHead2"
                  }
                >
                  Task History
                </div>
                <div className="historyHr"></div>
              </div>
            </div>
            {leadHistory && (
              <div>
                <div className="history1">
                  <div className="history1-left">
                    <div className="history-circle">
                      <div className="history-circle1"></div>
                      <div className="history-circle2"></div>
                    </div>
                    <div className="history1-text">
                      <div className="history-name">Ajay. P</div>
                      <div className="history1-text2 label3">
                        <div className="label3">
                          Jay. P Add Next Meeting Schedule{" "}
                        </div>
                        <div className="label3">Date 25-02-2024 02.00 PM</div>
                      </div>
                      <div className="history1-text3 label3">
                        <div className="history1-text3-1">
                          Lorem Ipsum has been the industry's standard ever
                          since
                        </div>
                        <div className="history1-text3-2">See more.....</div>
                      </div>
                      <div className="history1-pdf">
                        <div className="history1-pdf1">
                          <FaRegFilePdf size={18} />
                        </div>
                        <div className="history1-pdf2">Insurnace.pdf</div>
                      </div>
                    </div>
                  </div>
                  <div className="history1-right">20-02-2024</div>
                </div>
                <div className="history1">
                  <div className="history1-left">
                    <div className="history-circle">
                      <div className="history-circle1"></div>
                      <div className="history-circle2"></div>
                    </div>
                    <div className="history1-text">
                      <div className="history-name">Ajay. P</div>
                      <div className="history1-text2 label3">
                        <div className="label3">
                          Jay. P Add Next Meeting Schedule{" "}
                        </div>
                        <div className="label3">Date 25-02-2024 02.00 PM</div>
                      </div>
                      <div className="history1-text3 label3">
                        <div className="history1-text3-1">
                          Lorem Ipsum has been the industry's standard ever
                          since
                        </div>
                        <div className="history1-text3-2">See more.....</div>
                      </div>
                      <div className="history1-pdf">
                        <div className="history1-pdf1">
                          <FaRegFilePdf size={18} />
                        </div>
                        <div className="history1-pdf2">Insurnace.pdf</div>
                      </div>
                    </div>
                  </div>
                  <div className="history1-right">20-02-2024</div>
                </div>
              </div>
            )}
            {!leadHistory && (
              <div>
                <div className="history1">
                  <div className="history1-left">
                    <div className="history-circle">
                      <div className="history-circle1"></div>
                      <div className="history-circle2"></div>
                    </div>
                    <div className="history1-text">
                      <div className="history-name">Jay. P</div>
                      <div className="history1-text2 label3">
                        <div className="label3">Jay. P Add Next Follow Up </div>
                        <div className="label3">Date 25-02-2024 02.00 PM</div>
                      </div>
                      <div className="history1-text3 label3">
                        <div className="history1-text3-1">
                          Lorem Ipsum has been the industry's standard ever
                          since
                        </div>
                        <div className="history1-text3-2">See more.....</div>
                      </div>
                      <div className="history1-pdf">
                        <div className="history1-pdf1">
                          <FaRegFilePdf size={18} />
                        </div>
                        <div className="history1-pdf2">Insurnace.pdf</div>
                      </div>
                    </div>
                  </div>
                  <div className="history1-right">20-02-2024</div>
                </div>
                <div className="history1">
                  <div className="history1-left">
                    <div className="history-circle">
                      <div className="history-circle1"></div>
                      <div className="history-circle2"></div>
                    </div>
                    <div className="history1-text">
                      <div className="history-name">Jay. P</div>
                      <div className="history1-text2 label3">
                        <div className="label3">Jay. P Add Next Follow Up </div>
                        <div className="label3">Date 25-02-2024 02.00 PM</div>
                      </div>
                      <div className="history1-text3 label3">
                        <div className="history1-text3-1">
                          Lorem Ipsum has been the industry's standard ever
                          since
                        </div>
                        <div className="history1-text3-2">See more.....</div>
                      </div>
                      <div className="history1-pdf">
                        <div className="history1-pdf1">
                          <FaRegFilePdf size={18} />
                        </div>
                        <div className="history1-pdf2">Insurnace.pdf</div>
                      </div>
                    </div>
                  </div>
                  <div className="history1-right">20-02-2024</div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
      <div
        className={`Overlay ${displayModal ? "Show" : ""}`}
        onClick={() => setDisplayModal(!displayModal)}
      />
      <p className="Alert">{alert}</p>
    </div>
  );
}

export default ClientInfo;
