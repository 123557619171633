import httpClient from "./httpClient.ts";
import { RolewithPermission, UserLogin, UserRole, CurrentUserData, UpdateCurrentUserData, UserWithRole, ToggleStatusUser } 
from "../Domain/UserAuth/AppUser";
import { NavigateFunction } from "react-router-dom";
import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";

export const signup = async (
  payload: CurrentUserData,
  redirectTo: NavigateFunction
) => {
  try {
    storeTenantToLocal(payload.tenant);
    payload.cityId = 22;
    payload.stateId = 13;
    payload.countryId = 1;
    payload.isActive = true;
    await httpClient.post("users/selfregister/self-register", payload);
    redirectTo("/");
    snackBarStore.showSnackBar("Signup success", "success");
  } catch (error: any) {
    snackBarStore.showSnackBar(
      `Problem in Signup: ${error.response.data?.exception}`,
      "error"
    );
  }
};

export const userLoginApi = async (
  payload: UserLogin
) => {
  try {
    storeTenantToLocal(payload.tenant);
    const { data } = await httpClient.post(`tokens/gettoken`, 
      {email: payload.email, password: payload.password});
    storeAccessTokenToLocal(data.token);
    storeRefreshTokenToLocal(data.refreshToken);
    snackBarStore.showSnackBar("Login success", "success");
    // console.log({payload});
  } catch (error: any) {
    snackBarStore.showSnackBar(
      `Problem in login: ${error.response.data?.exception}`,
      "error"
    );

    console.log(error);
  }
};

export const forgotPassword = async (email: string): Promise<boolean> => {
  try {
    await httpClient.post("auth/forgot-password", { email });
    snackBarStore.showSnackBar("Instruction sent successfully", "success");
    return true;
  } catch (error: any) {
    snackBarStore.showSnackBar(`Problem: ${error.response.data}`, "error");

    console.log(error);
    return false;
  }
};

export const resetPassword = async (
  token: string,
  password: string
): Promise<boolean> => {
  try {
    await httpClient.post("auth/reset-password", { token, password });
    snackBarStore.showSnackBar("Reset successfully", "success");
    return true;
  } catch (error: any) {
    snackBarStore.showSnackBar(`Prblem: ${error.response.data}`, "error");
    console.log(error);
    return false;
  }
};

export const fetUserData = async (): Promise<Array<Partial<CurrentUserData>> | any> => {
    try {
      const { data } = await httpClient.get(`/currentuser/getprofile/profile`);
      return data;
    } catch (error) {
      snackBarStore.showSnackBar(`Prblem: ${error.response.data}`, "error");
      return error;
    }
  };

  export const fetchUserRoleByUserId = async (
    id: number
  ): Promise<Array<Partial<UserRole>> | any> => {
    try {
      const { data } = await httpClient.get(`users/getroles/${id}/roles`);
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  export const fetchUserRoleWithPermissionsByRoleId = async (
    roleId: number
  ): Promise<Array<Partial<RolewithPermission>> | any> => {
    try {
      const { data } = await httpClient.get(`roles/getbyidwithpermissions/${roleId}/permissions`);
      return data;
    } catch (error) {
      snackBarStore.showSnackBar(`Error while fetching Role Permission: ${error.response.data?.exception}`, "error");
    }
  };

  export const UpdateUserRoleWithPermissions = async (
    roleId: number, rData: RolewithPermission, redirectTo: NavigateFunction
  ) => {
    try {
      const { data } = await httpClient.put(`roles/updatepermissions/${roleId}/permissions`, rData);
      snackBarStore.showSnackBar(`${data}`, "success");
    } catch (error) {
      snackBarStore.showSnackBar(`Error while Saving Role Permission: ${error.response.data?.exception}`, "error");
    }
  };

export const fetchAllRoles = async () : Promise<Array<Partial<RolewithPermission>> | any> => {
  try {
    const { data } = await httpClient.get("roles/getlist");
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(`Error while fetching Roles: ${error.response.data?.exception}`, "error");
  }
};

export const handleRefreshToken = async () => {
  try {
    const { data } = await httpClient.get("auth/refresh-token");
    storeAccessTokenToLocal(data.accessToken);
    storeRefreshTokenToLocal(data.refreshToken);
  } catch (error) {
    console.log(error);
  }
};

export const logout = (redirectTo: NavigateFunction) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tenant");

  redirectTo("/");
};

export const updateCurrentUser = async (userData: UpdateCurrentUserData, redirectTo: NavigateFunction) => {
  try {
    userData.cityId = 22;
    userData.stateId = 13;
    userData.countryId = 1;
    await httpClient.put("currentuser/updateprofile/profile", userData);
    snackBarStore.showSnackBar(
      `Profile Updated Successfully`,
      "success"
    );
    redirectTo("/profile");
  } catch (error) {
    console.log(error);
  }
};

export const GetAllUsers = async (): Promise<Array<Partial<UserWithRole>> | any> => {
  try {
    const { data } = await httpClient.get("users/getlistwithroles");
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while fetching data ${error.response.data?.exception}`,
      "error"
    );
  }
};

export const InActiveUser = async (tData:ToggleStatusUser) => {
  try {
    const { data } = await httpClient.post(`users/togglestatus/${tData.userId}/toggle-status`, tData);
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while updating user: ${error.response.data?.exception}`,
      "error"
    );
  }
};

export const GetUserById = async (id: number): Promise<Array<Partial<UserWithRole>> | any> => {
  try {
    const { data } = await httpClient.get(`users/getbyidwithrole/${id}`);
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while fetching data ${error.response.data?.exception}`,
      "error"
    );
  }
};

export const CreateRole = async (userRole: UserRole) => {
  try {
    const { data } = await httpClient.post(`roles/registerrole`, userRole);
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while fetching data ${error.response.data?.exception}`,
      "error"
    );
  }
};

export const DeleteRole = async (id: number) => {
  try {
    const { data } = await httpClient.delete(`roles/delete/${id}`);
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while Deleting data ${error.response.data?.exception}`,
      "error"
    );
  }
};

export const UpdateUser = async (userId: number, cData: CurrentUserData) => {
  try {
    const { data } = await httpClient.put(`users/update/${userId}/update`, cData);
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while Updating New User ${error.response.data?.exception}`,
      "error"
    );
  }
}

export const CreateNewUser = async (cData: CurrentUserData): Promise<Array<Partial<CurrentUserData>> | any> => {
  try {
    const { data } = await httpClient.post("users/create", cData);
    return data;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error while Creating New User ${error.response.data?.exception}`,
      "error"
    );
  }
};

const storeAccessTokenToLocal = (accessToken: string): void =>
  localStorage.setItem("accessToken", accessToken);
const storeRefreshTokenToLocal = (refreshToken: string): void =>
  localStorage.setItem("refreshToken", refreshToken);
const storeTenantToLocal = (tenant: string): void =>
  localStorage.setItem("tenant", tenant);
