import React, { useState, useEffect } from 'react';
import login from "../../Assets/Svg/Group.png";
import logo from "../../Assets/png/Logo-Final.png";
import Button from '../../../Common/shared/Button/button';
import { useForm } from "react-hook-form";
import "../../../Common/shared/Input/input.css";
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../../Common/PageLoader/index';
import { useAuth } from "../../../Utils/userAuth.ts";
import { signup } from "../../../Services/authService.ts"
import { useLoading } from '../../../Services/ContextAPI/loadingContext.jsx';

export const RegisterPage = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm();

    const navigate = useNavigate();
    const [WhatsAppNo, setWhatsAppNo] = useState("");
    const isLoggedIn = useAuth();
    const { loading, startLoading, stopLoading } = useLoading();
    const phoneNumber = watch("phoneNumber", '');
    const syncInputs = watch("syncInputs", false);

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        data = {...data, tenant: 'root'};
        startLoading();
        await signup(data, navigate);
        stopLoading();
    };

    const validatePasswordMatch = (value) => {
        const password = watch('password');
        return password === value || "Passwords do not match";
    };

    return (
        <div className='pagewrapper'>
            <div className="loginContainer">
                <div className="left-section-r">
                    <div className="registerform" style={{ justifyContent: "flex-start", }}>
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                        <div className="loginHeading">
                            <h3>Register</h3>
                            <div className="label5 label5text">Login with your data that you entered during your registration</div>
                        </div>

                        <form className="loginFormInput" style={{ gap: "2vh" }} onSubmit={handleSubmit(onSubmit)}>
                            {/* <div className="register-input">
                                <div className="inputForm" style={{ width: "50%" }}>
                                    <label htmlFor="tenant">Organization Name</label>
                                    <input
                                        type='text'
                                        className={`input10 ${errors.tenant ? 'error-border' : ''}`}
                                        placeholder="Organization Name"
                                        {...register("tenant", {
                                            required: 'Organization Name is required',
                                        })}
                                        aria-invalid={errors.tenant ? 'true' : 'false'}
                                    />
                                    {errors.tenant && <div className="errorLogin label3">{errors.tenant.message}</div>}
                                </div>
                            </div> */}
                            <div className="register-input">
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type='text'
                                        className={`input10 ${errors.firstName ? 'error-border' : ''}`}
                                        placeholder="First Name"
                                        {...register("firstName", {
                                            required: 'Name is required',
                                        })}
                                        aria-invalid={errors.firstName ? 'true' : 'false'}
                                    />
                                    {errors.firstName && <div className="errorLogin label3">{errors.firstName.message}</div>}
                                </div>
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type='text'
                                        className={`input10 ${errors.lastName ? 'error-border' : ''}`}
                                        placeholder="Last Name"
                                        {...register("lastName", {
                                            required: 'Last Name is required',
                                        })}
                                        aria-invalid={errors.lastName ? 'true' : 'false'}
                                    />
                                    {errors.lastName && <div className="errorLogin label3">{errors.lastName.message}</div>}
                                </div>
                            </div>
                            <div className="register-input">
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type='text'
                                        className={`input10 ${errors.email ? 'error-border' : ''}`}
                                        placeholder="Email"
                                        {...register("email", {
                                            required: 'Email Address is required',
                                        })}
                                        aria-invalid={errors.email ? 'true' : 'false'}
                                    />
                                    {errors.email && <div className="errorLogin label3">{errors.email.message}</div>}
                                </div>
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        type='number'
                                        className={`input10 ${errors.phoneNumber ? 'error-border' : ''}`}
                                        placeholder="Phone Number"
                                        {...register("phoneNumber", {
                                            required: 'Phone Number is required',
                                        })}
                                        aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                                    />
                                    {errors.phoneNumber && <div className="errorLogin label3">{errors.phoneNumber.message}</div>}
                                </div>
                            </div>
                            <div className="register-input">
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <div className="wp-phone">
                                        <label htmlFor="phoneNumber">WhatsApp Number</label>
                                        <div className="wp-phone-2">
                                            <input
                                                type="checkbox"
                                                {...register("syncInputs")}
                                                checked={syncInputs}
                                                style={{ border: "2px solid #00f", display: "block", backgroundColor: "#fafa" }}
                                            />
                                            <div className="same-no">same as mobile number</div>
                                        </div>
                                    </div>
                                    <input
                                        className={`input10 ${errors.whatsAppNumber ? "error-border" : "" }`}
                                        {...register("whatsAppNumber", {
                                            required: syncInputs ? false : 'WhatsApp Number is required',
                                        })}
                                        type="number"
                                        placeholder="WhatsApp Number"
                                        aria-invalid={errors.whatsAppNumber ? 'true' : 'false'}
                                        value={syncInputs ? phoneNumber : WhatsAppNo} 
                                        onChange={(e) => {
                                            if (!syncInputs) {
                                                setWhatsAppNo(e.target.value);
                                            }
                                          }}
                                    />
                                    {errors.whatsAppNumber && <div className="errorLogin label3">{errors.whatsAppNumber.message}</div>}
                                </div>
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="userName">username</label>
                                    <input
                                        type='text'
                                        className={`input10 ${errors.userName ? 'error-border' : ''}`}
                                        placeholder="Industry"
                                        {...register("userName", {
                                            required: 'username is required',
                                        })}
                                        aria-invalid={errors.userName ? 'true' : 'false'}
                                    />
                                    {errors.userName && <div className="errorLogin label3">{errors.userName.message}</div>}
                                </div>
                            </div>
                            <div className="register-input">
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="Password">Password</label>
                                    <input
                                        placeholder="Password"
                                        className={`input10 ${errors.password ? 'error-border' : ''}`}
                                        type='password'
                                        {...register("password", {
                                            required: 'Password is required',
                                        })}
                                        aria-invalid={errors.password ? 'true' : 'false'}
                                    />
                                    {errors.password && <div className="errorLogin label3">{errors.password.message}</div>}
                                </div>
                                <div className="inputForm" style={{ width: "100%" }}>
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <input
                                        placeholder="Confirm Password"
                                        className={`input10 ${errors.confirmPassword ? 'error-border' : ''}`}
                                        type='password'
                                        {...register("confirmPassword", {
                                            required: 'Confirm Password is required',
                                            validate: validatePasswordMatch,
                                        })}
                                        aria-invalid={errors.confirmPassword ? 'true' : 'false'}
                                    />
                                    {errors.confirmPassword && <div className="errorLogin label3">{errors.confirmPassword.message}</div>}
                                </div>
                            </div>


                            <div className="buttoncontainer">
                                {loading ? (
                                    <div className="loader">

                                    </div>
                                ) : (
                                    <Button variant="largeButton">Register</Button>
                                )}
                            </div>
                            <div className="downContainer">
                                <div className="downcontainer2">
                                    <div className="label2 navigator" style={{ fontWeight: "500", color: "#696e77e6" }} onClick={() => navigate('/')}>Already have an account? Sign in</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="login-right-section-r" >
                    <div className="loginImage">
                        <img src={login} style={{ maxWidth: "450px" }} alt="" className="loginImg" />
                    </div>
                </div>
            </div>
        </div>
    )
}
