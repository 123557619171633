import { jwtDecode } from "jwt-decode"; // Correct import
import snackBarStore from "../Common/snackBar/store/snackbarStore.ts"; // Update path as per your project structure
import { redirect } from "react-router-dom";

type AccessTokenType = {
  fullName: string;
  email: string;
  id: string;
  image_url: string;
  tenant: string;
  exp: number;
  role: string;
};

export const useAuth = (): boolean => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken || accessToken.trim().toLowerCase() === "undefined") {
    return false;
  }

  try {
    const decoded = jwtDecode<AccessTokenType>(accessToken);
    if (isTokenExpired(decoded.exp)) return false;

    const nameIdentifier =
      decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    return !!nameIdentifier;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error decoding token: ${(error as Error).message}`,
      "error"
    );
    return false;
  }
};

export const isTokenExpired = (exp: number): boolean => {
  const currentTime = Math.floor(Date.now() / 1000);
  return exp < currentTime;
};

export const getAuthUser = (): AccessTokenType | null => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) return null;

  try {
    const decoded = jwtDecode<AccessTokenType>(accessToken);
    if (!isTokenExpired(decoded.exp)) {
      return {
        fullName: decoded.fullName,
        email: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
        id: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
        image_url: decoded.image_url,
        tenant: decoded.tenant,
        exp: decoded.exp,
        role: decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
      };
    }
  } catch (error) {
    snackBarStore.showSnackBar(
      `Error decoding token: ${(error as Error).message}`,
      "error"
    );
  }

  return null;
};

export const GetApiForImg = (): string => {
  return "https://opticalerp.in:85/api/";
};

export const GetApi = (): string => {
  return "https://opticalerp.in:85/api/";
};
